<template>
    <div>
        <input
            ref="fileInput"
            type="file"
            class="invisible"
            :multiple="multiple"
            :accept="acceptedFileTypes"
            @change="onFileInputChange">
        <v-card
            :class="{ 'grey lighten-2': dragover, 'outlined-card': true }"
            @drop.prevent="onDrop($event)"
            @dragover.prevent="dragover = true"
            @dragenter.prevent="dragover = true"
            @dragleave.prevent="dragover = false"
            @click="openFileExplorer">
            <v-card-text>
                <div
                    class="d-flex flex-column"
                    dense
                    align="center"
                    justify="center">
                    <v-icon
                        :class="[dragover ? 'mt-3' : 'mt-5', dragover ? 'mb-5' : 'mb-3' ]"
                        size="60">
                        mdi-cloud-upload
                    </v-icon>
                    <p>
                        {{ displayMessage }}
                    </p>
                </div>
                <v-chip
                    v-for="file in filesToUpload"
                    :key="file.name"
                    class="mr-1 mt-1"
                    color="secondary">
                    {{ truncateFileName(file.name) }}
                    <span class="ml-3">
                        {{ toMegabytes(file.size) }} MB
                    </span>
                    <v-icon right>
                        mdi-{{ file.uploaded ? "check-circle" : "cloud-upload" }}
                    </v-icon>
                </v-chip>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { toMegabytes, truncateFileName, uploadFile } from '@/services/fileService';

export default {
    props: {
        multiple: {
            type: Boolean,
            default: false
        },
        message: {
            type: String,
            default: null
        },
        allowedFileTypes: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            dragover: false,
            filesToUpload: []
        };
    },
    computed: {
        displayMessage() {
            if (this.message) {
                return this.message;
            }
            let messageStart = this.multiple ?
                'Drop your file(s) here' :
                'Drop your file here';
            return messageStart + ' or click to browse';
        },
        acceptedFileTypes() {
            return this.allowedFileTypes && this.allowedFileTypes.length > 0
                ? this.allowedFileTypes.join(',')
                : '';
        }
    },
    methods: {
        openFileExplorer() {
            this.$refs.fileInput.click();
        },
        async onFileInputChange(event) {
            const filesToUpload = [...event.target.files];
            await this.handleFiles(filesToUpload);
        },
        async onDrop(event) {
            this.dragover = false;
            const filesToUpload = [...event.dataTransfer.files];
            await this.handleFiles(filesToUpload);
        },
        async handleFiles(files) {
            const filteredFiles = this.filterFilesByType(files);

            if (!this.multiple && filteredFiles.length > 1) {
                this.$store.dispatch('alert/error', 'Only one file can be uploaded at a time.');
                return;
            }

            filteredFiles.forEach((f) => (f.uploaded = false));
            this.filesToUpload = filteredFiles;
            await this.submit();
        },
        filterFilesByType(files) {
            if (!this.allowedFileTypes || this.allowedFileTypes.length === 0) {
                return files;
            }
            return files.filter(file => {
                const fileType = file.type || file.name.split('.').pop();
                return this.allowedFileTypes.includes(fileType);
            });
        },
        async submit() {
            if (!this.filesToUpload.length) {
                return;
            }

            this.$emit("uploading")
            const files = [];
            for (let i = 0; i < this.filesToUpload.length; i++) {
                const fileToUpload = this.filesToUpload[i];
                const file = await uploadFile(fileToUpload);
                files.push(file);
                fileToUpload.uploaded = true;
                this.$set(this.filesToUpload, i, fileToUpload);
            }
            if (!this.multiple) {
                this.$emit('input', files[0]);
                return;
            }
            this.$emit('input', files);
        },
        toMegabytes,
        truncateFileName
    }
};
</script>
