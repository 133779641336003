import router from "@/routes";

const publicPaths = [
    "/login",
    "/reset-password",
    "/request-reset-password",
    "/new-user-set-password",
    "/sign-up",
    "/two-factor-authentication",
    "/accept-invitation",
    "/terms-and-conditions",
    "/privacy-policy",
];

export function currentRouteIsPublic() {
    return routeIsPublic(router.currentRoute);
}

export function routeIsPublic(route) {
    return publicPaths.some(publicPath =>
        publicPath == route.path
    );
}

export function goTo(path) {
    if (router.currentRoute.path !== path) {
        router.push(path);
    }
}

export default {
    goTo,
    routeIsPublic,
    currentRouteIsPublic
};
