import axios from "axios";

export async function onboardSchool(schoolName, numberOfTeachers, schoolAdminEmail, paymentMethod)
{
    return await axios.post(
        "/api/Subscriptions/Onboard",
        {
            schoolName: schoolName,
            numberOfTeachers: numberOfTeachers,
            schoolAdminEmail: schoolAdminEmail,
            paymentMethod: paymentMethod,
        }
    );
}
